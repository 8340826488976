
  import React from 'react';
  import COMPONENTS from '../components';
  import { get } from 'lodash';
  import PageLayout from '../layout';
  import { graphql } from 'gatsby';

  class Page extends React.Component {
    state = {
      data: [],
    };

    componentDidMount() {
      let slices;

      const data = get(this, 'props.data.indexJson');

      if (data) {
        slices = Object.entries(data).map(([key, content]) => ({
          componentName: key,
          ...content,
        }));

        slices.sort(
          ({ position: positionA }, { position: positionB }) =>
            positionA - positionB
        );

        this.setState({
          data: slices,
        });
      }
    }

    render() {
      return (
        <PageLayout>
          {get(this, 'state.data').map(({ componentName, data }, index) => {
            const ComponentType = COMPONENTS[componentName];
            return <ComponentType key={index} {...data} />;
          })}
        </PageLayout>
      );
    }
  }

  export default Page;

  export const pageQuery = graphql`
    query {
      indexJson {
        
      
      
      
      
      
      
      
      
      
      
      
      
      Hero0 {
        position
        componentName
        data {
           image title subtitle
        }
      }
    
      TableRow1 {
        position
        componentName
        data {
           content {  title textBody }
        }
      }
    
      CTA2 {
        position
        componentName
        data {
           title textBody button {  text link }
        }
      }
    
      Divider3 {
        position
        componentName
        data {
           height backgroundColor
        }
      }
    
      BannerMask4 {
        position
        componentName
        data {
           media {  type src } corners height
        }
      }
    
      Block3Column5 {
        position
        componentName
        data {
           background content {  title textBody link {  linkTxt } }
        }
      }
    
      SectionSeparator6 {
        position
        componentName
        data {
           sectionName
        }
      }
    
      Ventures7 {
        position
        componentName
        data {
           ventures {  title textBody imageBlock button {  text link } avatarProfiles {  image textBody textInfo } iconGroup {  social link } }
        }
      }
    
      CTAPhoto8 {
        position
        componentName
        data {
           image title imageSide textBody button {  text link }
        }
      }
    
      Divider9 {
        position
        componentName
        data {
           height
        }
      }
    
      SectionSeparator10 {
        position
        componentName
        data {
           sectionName
        }
      }
    
      Article3Column11 {
        position
        componentName
        data {
           header articles {  link image date textBody }
        }
      }
    
      }
    }
  `